import {Injectable} from '@angular/core';
import {Config} from "../config/config";
import {TYPE} from "../types/types";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {CustomerService} from "./customer.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private url = Config.DEFAULT_STORE_STRING === 'store' ? environment.apiUrlStore : environment.apiUrlDefault;
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(
    private http: HttpClient,
    private customerService: CustomerService
  ) {
  }

  public setUrl(url: string): void {
    this.url = url;
  }

  public getUrl(): string {
    return this.url;
  }

  public getLanguages(): Observable<TYPE.Languages> {
    return this.http.get<TYPE.Languages>(this.url + '/' + Config.API_VERSION_STRING + '/languages', {headers: this.headers});
  }

  public getCustomer(customerKey: string): Observable<TYPE.Customer> {
    return this.http.get<TYPE.Customer>(this.url + '/' + Config.API_VERSION_STRING + '/customer/key/' + customerKey, {headers: this.headers});
  }

  public getCustomerExisting(customerKey: string): Observable<TYPE.Customer> {
    return this.http.get<TYPE.Customer>(this.url + '/' + Config.API_VERSION_STRING + '/customer/existing/' + customerKey, {headers: this.headers});
  }

  public setCustomerRegister(extension: string, first_name: string, last_name: string, email: string, user_name: string, password: string, locale: string): Observable<TYPE.Customer> {
    return this.http.post<TYPE.Customer>(
      this.url + '/' + Config.API_VERSION_STRING + '/customer/register',
      {
        extension: extension,
        first_name: first_name,
        last_name: last_name,
        email: email,
        user_name: user_name,
        password: password,
        locale: locale.toLocaleLowerCase()
      },
      {
        headers: this.headers
      });
  }

  public getUserFromToken(token: string, device_id: string | null, push_id: string | null): Observable<TYPE.User> {
    return this.http.post<TYPE.User>(
      this.url + '/' + Config.API_VERSION_STRING + '/user/token',
      {
        token: token,
        device_id: device_id,
        push_id: push_id,
        device: 'web',
        app_color: 'green' // there is no other firebase messaging service, web uses green
      },
      {
        headers: this.headers
      });
  }

  public getUserFromForm(user_name: string, password: string, device_id: string | null, push_id: string | null): Observable<TYPE.User> {
    return this.http.post<TYPE.User>(
      this.url + '/' + Config.API_VERSION_STRING + '/user/login/' + this.customerService.getCustomer()?.id,
      {
        user_name: user_name,
        password: password,
        device_id: device_id,
        push_id: push_id,
        device: 'web',
        app_color: 'green' // there is no other firebase messaging service, web uses green
      },
      {
        headers: this.headers
      });
  }

  public resetUserPassword(user_name: string): Observable<boolean> {
    return this.http.post<boolean>(
      this.url + '/' + Config.API_VERSION_STRING + '/user/resetPassword/' + this.customerService.getCustomer()?.id,
      {
        user_name: user_name
      },
      {
        headers: this.headers
      });
  }

  public setUserRegister(first_name: string, last_name: string, email: string, user_name: string, password: string) {
    return this.http.post(
      this.url + '/' + Config.API_VERSION_STRING + '/user/register/' + this.customerService.getCustomer()?.id,
      {
        first_name: first_name,
        last_name: last_name,
        email: email,
        user_name: user_name,
        password: password
      },
      {
        headers: this.headers
      });
  }
}
